var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-content flex-container" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name"
          }
        ],
        attrs: { type: "text", placeholder: _vm.$t("titlePlaceHolder") },
        domProps: { value: _vm.name },
        on: {
          keydown: function($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.saveComponent($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          }
        }
      }),
      _vm.nameChanged
        ? [
            _c("img", {
              staticClass: "cancel-icon",
              attrs: { src: _vm.cancelIcon },
              on: { click: _vm.undoNameChanges }
            }),
            _c("img", {
              staticClass: "check-icon",
              attrs: { src: _vm.checkIcon },
              on: { click: _vm.saveComponent }
            })
          ]
        : _vm._e(),
      _vm.lessons
        ? _c(
            "div",
            { staticClass: "content-list" },
            [
              _c(
                "transition-group",
                { attrs: { name: "fade" } },
                _vm._l(_vm.lessons, function(lesson) {
                  return _c(
                    "div",
                    {
                      key: "lesson" + lesson.id + lesson.number,
                      staticClass: "list-item row",
                      on: {
                        click: function($event) {
                          _vm.editLesson(lesson)
                        }
                      }
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.getLessonNumber(lesson)))]),
                      _c("span", [
                        _vm._v(
                          _vm._s(lesson.name[_vm.locale] || _vm.$t("lesson"))
                        )
                      ])
                    ]
                  )
                })
              )
            ],
            1
          )
        : _vm._e(),
      _c("span", { staticClass: "other-content" }, [
        _vm._v(_vm._s(_vm.$t("otherContentTag")))
      ]),
      _c(
        "div",
        { staticClass: "content-list" },
        [
          _c(
            "transition-group",
            { attrs: { name: "fade" } },
            _vm._l(_vm.biographies, function(biography) {
              return _c(
                "div",
                {
                  key: "biogrphy:" + biography.id,
                  staticClass: "list-item row",
                  on: {
                    click: function($event) {
                      _vm.editBiography(biography)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(biography.name[_vm.locale] || "Biography"))
                  ])
                ]
              )
            })
          ),
          _c(
            "transition-group",
            { attrs: { name: "fade" } },
            _vm._l(_vm.unitStories, function(story) {
              return _c(
                "div",
                {
                  key: "story:" + story.id,
                  staticClass: "list-item row",
                  on: {
                    click: function($event) {
                      _vm.editUnitStory(story)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(story.title[_vm.locale] || "Story"))
                  ])
                ]
              )
            })
          ),
          _c(
            "transition-group",
            { attrs: { name: "fade" } },
            _vm._l(_vm.assessments, function(assessment) {
              return _c(
                "div",
                {
                  key: "assessment:" + assessment.id,
                  staticClass: "list-item row",
                  on: {
                    click: function($event) {
                      _vm.editAssessment(assessment)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("assessment")) +
                        " - " +
                        _vm._s(_vm.$t("lesson")) +
                        "\n          " +
                        _vm._s(assessment.number)
                    )
                  ])
                ]
              )
            })
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                staticClass: "list-item row",
                on: { click: _vm.goToVocabularyManager }
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("vocabulary")))])]
            )
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.component.hasTimeLine
              ? _c(
                  "div",
                  {
                    staticClass: "list-item row",
                    on: { click: _vm.goToTimeLineBuilder }
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("timeline")))])]
                )
              : _vm._e()
          ]),
          _c(
            "transition-group",
            { attrs: { name: "fade" } },
            [
              _c(
                "div",
                {
                  key: "teacher page unit:" + _vm.component.id,
                  staticClass: "list-item row",
                  on: {
                    click: function($event) {
                      _vm.goToTeacherPage(_vm.component)
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("teacherPage")) +
                        " - " +
                        _vm._s(_vm.$t("unit")) +
                        "\n          " +
                        _vm._s(_vm.component.number)
                    )
                  ])
                ]
              ),
              _vm._l(_vm.teacherPageLessons, function(lesson) {
                return _c(
                  "div",
                  {
                    key: "teacher page:" + lesson.id,
                    class: lesson.number === 0 ? "d-none" : "list-item row",
                    on: {
                      click: function($event) {
                        _vm.goToTeacherPage(lesson)
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("teacherPage")) +
                          " - " +
                          _vm._s(_vm.$t("lesson")) +
                          "\n          " +
                          _vm._s(lesson.number)
                      )
                    ])
                  ]
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }