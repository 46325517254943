<template>
  <div class="unit-content flex-container">
    <input
      type="text"
      v-model="name"
      :placeholder="$t('titlePlaceHolder')"
      v-on:keydown.enter="saveComponent"
    />
    <template v-if="nameChanged">
      <img :src="cancelIcon" @click="undoNameChanges" class="cancel-icon" />
      <img :src="checkIcon" @click="saveComponent" class="check-icon" />
    </template>
    <div class="content-list" v-if="lessons">
      <transition-group name="fade">
        <div
          class="list-item row"
          v-for="lesson of lessons"
          :key="'lesson' + lesson.id + lesson.number"
          @click="editLesson(lesson)"
        >
          <span>{{ getLessonNumber(lesson) }}</span>
          <span>{{ lesson.name[locale] || $t("lesson") }}</span>
        </div>
      </transition-group>
    </div>
    <span class="other-content">{{ $t("otherContentTag") }}</span>
    <div class="content-list">
      <transition-group name="fade">
        <div
          class="list-item row"
          v-for="biography of biographies"
          :key="'biogrphy:' + biography.id"
          @click="editBiography(biography)"
        >
          <span>{{ biography.name[locale] || "Biography" }}</span>
        </div>
      </transition-group>
      <transition-group name="fade">
        <div
          class="list-item row"
          v-for="story of unitStories"
          :key="'story:' + story.id"
          @click="editUnitStory(story)"
        >
          <span>{{ story.title[locale] || "Story" }}</span>
        </div>
      </transition-group>      
      <transition-group name="fade">
        <div
          class="list-item row"
          v-for="assessment of assessments"
          :key="'assessment:' + assessment.id"
          @click="editAssessment(assessment)"
        >
          <span
            >{{ $t("assessment") }} - {{ $t("lesson") }}
            {{ assessment.number }}</span
          >
        </div>
      </transition-group>
      <transition name="fade">
        <div class="list-item row" @click="goToVocabularyManager">
          <span>{{ $t("vocabulary") }}</span>
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="component.hasTimeLine"
          class="list-item row"
          @click="goToTimeLineBuilder"
        >
          <span>{{ $t("timeline") }}</span>
        </div>
      </transition>
      <transition-group name="fade">
        <div
          class="list-item row"
          :key="'teacher page unit:' + component.id"
          @click="goToTeacherPage(component)"
        >
          <span
            >{{ $t("teacherPage") }} - {{ $t("unit") }}
            {{ component.number }}</span
          >
        </div>
        <div
          :class="lesson.number === 0 ? 'd-none': 'list-item row'"
          v-for="lesson of teacherPageLessons"
          :key="'teacher page:' + lesson.id"
          @click="goToTeacherPage(lesson)"
        >
          <span
            >{{ $t("teacherPage") }} - {{ $t("lesson") }}
            {{ lesson.number }}</span
          >
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
import BackOfficeComponentMixin from "@/components/BackOffice/Mixins/BackOfficeComponentMixin.js";
export default {
  name: "UnitDashboardBuilderComponentContent",
  mixins: [BackOfficeComponentMixin],
  computed: {
    lessons() {
      return this.component.lessons;
    },
    biographies() {
      return this.component.biographies;
    },
    unitStories() {
      return this.component.unitStories;
    },
    assessments() {
      return this.component.lessons.filter((e) => e.hasAssessment);
    },
    teacherPageLessons() {
      return this.lessons.filter((e, index) => index > 0);
    },
    name: {
      get() {
        return this.component.name[this.locale];
      },
      set(value) {
        this.setComponentPropertyValue("name", value, this.component);
      },
    },
    nameChanged() {
      return this.component.originalName[this.locale] != this.name;
    },
    cancelIcon() {
      return require("@/assets/icons/ic_close.svg");
    },
    checkIcon() {
      return require("@/assets/icons/ic_check_blue.svg");
    },
  },
  methods: {
    // editLesson(lesson) {
    //   let lessonType = lesson.lessonTypeId;
    //   if (
    //     lessonType == this.$config.soaConfig.lessonTypes.activity.id ||
    //     lessonType == this.$config.soaConfig.lessonTypes.skill.id
    //   ) {
    //     this.editActivityOrSkill(lesson);
    //   } else {
    //     let route =
    //       lesson.lessonTypeId == this.$config.soaConfig.lessonTypes.overview.id
    //         ? {
    //             name: "OverviewBuilder",
    //             params: {
    //               unitId: this.component.id,
    //             },
    //             query: {
    //               unitNumber: this.component.number,
    //             },
    //           }
    //         : {
    //             name: "LessonBuilder",
    //             params: {
    //               unitId: this.component.id,
    //               lessonId: lesson.id,
    //             },
    //             query: {
    //               unitNumber: this.component.number,
    //             },
    //           };

    //     this.$router.push(route);
    //   }
    // },
    editLesson(lesson) {
      let lessonType = lesson.lessonTypeId;
      let route = null;
      if(lessonType === this.$config.soaConfig.lessonTypes.overview.id) {
        route = {
          // name: "OverviewBuilder",
          name:"OpenerBuilder",
          params: {
            unitId: this.component.id,
          },
        };
      } else if(lessonType === this.$config.soaConfig.lessonTypes.lesson.id) {
        route = {
          name: "LessonBuilder",
          params: {
            unitId: this.component.id,
            lessonId: lesson.id,
          },
        };
      } else if(lessonType === this.$config.soaConfig.lessonTypes.skill.id) {
        route = {
          name: "SkillLessonBuilder",
          params: {
            unitId: this.component.id,
            lessonId: lesson.id,
          },
        };
      } else if(lessonType === this.$config.soaConfig.lessonTypes.story.id) {
        route = {
          name: "LessonStoryBuilder",
          params: {
            unitId: this.component.id,
            lessonId: lesson.id,
          },
        };
      }
      
      this.$router.push(route);
    },
    lessonName(lesson) {
      return lesson.lessonTypeId ==
        this.$config.soaConfig.lessonTypes.activity.id
        ? "Activity"
        : lesson.lessonTypeId == this.$config.soaConfig.lessonTypes.skill.id
        ? "Skill"
        : "";
    },
    editActivityOrSkill(lesson) {
      if (lesson.hasAssessment) {
        let route = {
          name: "AssessmentBuilder",
          params: {
            unitId: this.component.id,
            lessonId: lesson.id,
          },
          query: {
            lessonNumber: lesson.number,
            lessonName: this.lessonName(lesson),
          },
        };
        this.$router.push(route);
      } else {
        this.$store
          .dispatch("addAssessmentToLesson", lesson.id)
          .then(() => {
            this.setComponentPropertyValue("hasAssessment", true, lesson);
            this.editActivityOrSkill(lesson);
          })
          .catch(() => {
            this.$store.commit("addSnackBarNotification", {
              message: this.$t("failedToEditAssessmentAlert"),
            });
          });
      }
    },
    setComponentPropertyValue(propertyName, value, component) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: component,
        propertyName: propertyName,
        value: value,
        language: this.locale
      });
    },
    getLessonNumber(lesson) {
      return lesson.lessonTypeId == this.$config.soaConfig.lessonTypes.lesson.id
        ? lesson.number
        : "";
    },
    editBiography(biography) {
      let _route = {
        name: "BiographyBuilder",
        params: {
          biographyId: biography.id,
          unitId: this.component.id,
        },
        query: {
          unitNumber: this.component.number,
        },
      };

      this.$router.push(_route);
    },
    
    editUnitStory(story) {
      let _route = {
        name: "StoryBuilder",
        params: {
          unitstoryId: story.id,
          unitId: this.component.id,
        },
        query: {
          unitNumber: this.component.number,
        },
      };

      this.$router.push(_route);
    },
    editAssessment(assessment) {
      this.$router.push({
        name: "AssessmentBuilder",
        params: {
          lessonId: assessment.id,
          unitId: this.component.id,
        },
        query: {
          unitNumber: this.component.number,
          lessonNumber: assessment.number,
        },
      });
    },
    saveComponent() {
      this.$store.dispatch("updateUnitName", this.component);
      this.setComponentPropertyValue("originalName",this.name, this.component);
    },
    goToVocabularyManager() {
      this.$router.push({
        name: "VocabularyManager",
        params: {
          unitId: this.component.id,
        },
        query: {
          unitNumber: this.component.number,
        },
      });
    },
    goToTimeLineBuilder() {
      this.$router.push({
        name: "TimeLineBuilder",
        params: {
          unitId: this.component.id,
        },
        query: {
          unitNumber: this.component.number,
        },
      });
    },
    undoNameChanges() {
      this.name = this.component.originalName[this.locale];
    },
    goToTeacherPage(content) {
      this.$router.push({
        name: "TeacherPageBuilder",
        params: {
          teacherPageId: content.skillsId,
          unitId: this.component.id,
        },
        query: {
          title: content.name[this.locale],
          unitNumber: this.component.number,
          lessonNumber: content != this.component ? content.number : 0,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unit-content {
  flex-direction: column;
  position: relative;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);

  > span {
    height: 32px;
    width: 704px;
  }
  input,
  span,
  select {
    font-family: "Roboto";
    font-size: 12px;
    line-height: 16px;
  }
  input {
    height: 40px;
    width: 654px;
    border: 1px solid #cfcfcf;
    background-color: #ffffff;
    margin-left: 24px;
    margin-right: 16px;
    letter-spacing: 0.4px;
    position: relative;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 80px;
    text-overflow: ellipsis;
    padding-bottom: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #000;
  }

  .other-content {
    margin-top: 16px;
    margin-left: 24px;
    text-transform: uppercase;
    height: unset;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .cancel-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 80px;
    top: 12px;
    cursor: pointer;
  }

  .check-icon {
    cursor: pointer;
    width: 22px;
    height: 18px;
    position: absolute;
    right: 44px;
    top: 12px;
  }

  .content-list {
    width: 703.94px;
    margin-top: 8px;
    cursor: pointer;
    .list-item {
      width: 100%;
      height: 40px;
      margin-left: 0;
      span {
        text-transform: capitalize;
        align-self: center;
        color: rgba(0, 0, 0, 0.6);
        font-family: "Roboto";
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
      }
      span:nth-child(1) {
        margin-left: 56px;
        min-width: 30px;
      }
      span:nth-child(2) {
        margin-left: 20px;
        max-width: 550px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .content-list:first-of-type {
    span {
      span:nth-child(1) {
        font-family: "Montserrat";
        font-weight: bold;
      }
    }
  }
}
.d-none{
  display:none
}
</style>


