var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dashboard-builder-item flex-container",
      class: { "item-expanded": !_vm.isCollapsed }
    },
    [
      _c("div", { staticClass: "unit-details flex-container" }, [
        _c("img", {
          attrs: { src: _vm.expandIcon },
          on: { click: _vm.expandContent }
        }),
        _c("span", { staticClass: "legend" }, [
          _vm._v(_vm._s(_vm.$t("unit")) + " " + _vm._s(_vm.component.number))
        ]),
        _c("span", [_vm._v(_vm._s(_vm.component.name[_vm.locale]))]),
        _c("img", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: "Go to Unit Builder",
              expression: "'Go to Unit Builder'"
            }
          ],
          attrs: { src: _vm.chevronIcon },
          on: { click: _vm.goToUnitBuilder }
        }),
        _vm.isCollapsed
          ? _c("img", { attrs: { src: _vm.dragHandlerIcon } })
          : _c(
              "button",
              {
                staticClass: "btn secondary",
                on: {
                  click: function($event) {
                    _vm.showModal = true
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("delete")) +
                    " " +
                    _vm._s(_vm.$t("unit")) +
                    "\n    "
                )
              ]
            )
      ]),
      !_vm.isCollapsed
        ? _c("unit-content", { attrs: { component: _vm.component } })
        : _vm._e(),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.deleteUnit,
                "text-button": _vm.$t("delete")
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "delete-message row" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("deleteConfirmationMessage", {
                        "0": _vm.$t("unit")
                      })
                    )
                  )
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }