<template>
    <div class="dashboard-builder builder flex-container" v-if="components">
        <div class="units-container flex-container custom-scrollbar"> 
            <draggable  v-model="components" :options="dragOptions" @change="onChange">
                <transition-group name="flip-list" tag="ul">
                    <li v-for="component in components" :key="component.id" >
                        <UnitDashboardBuilderComponent :component="component" />
                    </li>
                </transition-group>
            </draggable>
            <button class="btn secondary" @click="createUnit">{{$t('addUnitTag')}}</button> 
        </div>
        <properties-panel :save-component="saveComponent"/>
    </div>
</template>
<script>
import UnitDashboardBuilderComponent from '@/components/BackOffice/UnitDashboardBuilder/UnitDashboardBuilderComponent.vue'
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'
import draggable from 'vuedraggable'

export default {
    name: 'UnitDashboardBuilder',
    mixins:[BuilderMixin], 
    components:{
        UnitDashboardBuilderComponent,
        draggable, 
    },
    computed:{
        components:{
            get(){
                return this.$store.state.UnitDashboardComponents
            },
            set(value){
                this.$store.commit('changeUnitDashboardComponentOrder', value)
            }
        },
        dragOptions(){    
            return {
                animation: 0,
                group: "components",
                ghostClass: "ghost"
            }
        },
        component(){
            return this.$store.state.currentBackOfficeComponent
        }, 
    },
    methods:{
        onChange({moved}){
            this.$store.dispatch('reoderUnitDashboardComponent', moved.element)
        }, 
        createUnit(){
           this.$store.commit('updateLoadingStatus', true)
            setTimeout(()=>{
                this.$store.dispatch('createUnit')
                    .then(()=>{
                        this.$store.dispatch('loadUnitDashboardComponents')
                    })
                this.$store.commit('updateLoadingStatus', false)
            }, 1000)
        }, 
        saveComponent(){
            if(!this.component)
                return
            let unitInDashboard = this.components.find(e => e === this.component)
            if(!unitInDashboard)
                return
            try{
                this.$store.dispatch('updateUnitName', this.component)
            }catch(err){
                // eslint-disable-next-line
                console.log(err)
                // eslint-disable-next-line
                console.log(this.component)
            }
        },
        setComponentPropertyValue(component, value, property){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {component: component, propertyName: property, value: value})
        },
    },
    created(){
        this.$store.commit('updateSwitchButtonDisplayStatus', false)
        this.$store.commit('updateContentSectionState', true)
        this.$store.dispatch('loadUnitDashboardComponents')
    }, 
    updated(){
        this.$route.query.selected && this.components
                    ? this.$store.commit('updateCurrentBackOfficeComponent',this.components
                        .find((e) => e.id == this.$route.query.selected))
                    : this.$store.commit('updateCurrentBackOfficeComponent', undefined)
        this.$router.push({
            name: 'UnitDashboardBuilder'
        })
    }
}
</script>
<style lang="less" scoped>
.ghost{
    opacity: 0.8;
}
.dashboard-builder{
    margin-top: 104px;
    .units-container{
        width: 724px;
        overflow-y: auto;
        overflow-x: hidden;
        flex-direction: column;
        padding-bottom: 19px;
        min-width: 704px;
    }
    
    .dashboard-builder-item:first-of-type{
        margin-top: 16px;
    }

    .btn{
        margin-top: 16px;
        margin-bottom: 19px;
        width: 96px;
    }
}
</style>


