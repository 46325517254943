var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.components
    ? _c(
        "div",
        { staticClass: "dashboard-builder builder flex-container" },
        [
          _c(
            "div",
            { staticClass: "units-container flex-container custom-scrollbar" },
            [
              _c(
                "draggable",
                {
                  attrs: { options: _vm.dragOptions },
                  on: { change: _vm.onChange },
                  model: {
                    value: _vm.components,
                    callback: function($$v) {
                      _vm.components = $$v
                    },
                    expression: "components"
                  }
                },
                [
                  _c(
                    "transition-group",
                    { attrs: { name: "flip-list", tag: "ul" } },
                    _vm._l(_vm.components, function(component) {
                      return _c(
                        "li",
                        { key: component.id },
                        [
                          _c("UnitDashboardBuilderComponent", {
                            attrs: { component: component }
                          })
                        ],
                        1
                      )
                    })
                  )
                ],
                1
              ),
              _c(
                "button",
                { staticClass: "btn secondary", on: { click: _vm.createUnit } },
                [_vm._v(_vm._s(_vm.$t("addUnitTag")))]
              )
            ],
            1
          ),
          _c("properties-panel", {
            attrs: { "save-component": _vm.saveComponent }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }